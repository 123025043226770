import React, { useState, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import MarkerIcon from './img/marker.svg';
import CheckIcon from './img/check.svg';
import systemAffiliation from './data/system_affiliation.json';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import 'overlayscrollbars/styles/overlayscrollbars.css';

const grouppedData = systemAffiliation.reduce((r, e) => {
  const group = e.name[0];
  if(!r[group]) r[group] = {group, children: [e]}
  else r[group].children.push(e);
  return r;
}, {})

const Search = ({ map, onChange }) => {
  const searchRef = useRef(null);
  const defaultState = useMemo(() => ({ id: 0, name: 'List of hospitals (show all)' }), []);
  const [state, setState] = useState(defaultState);
  const [isActive, setIsActive] = useState(false);

  const onListItemClick = (state) => {
    setState(state);
    setIsActive(false);
  }

  useEffect(() => {
    if (map) onChange(state.id);
  }, [map, state]); // eslint-disable-line

  useEffect(() => {
    if (!searchRef.current) return;
    const closeSearch = e => {
      if (!searchRef.current.contains(e.target)) {
        setIsActive(false);
      }
    }
    document.addEventListener('mousedown', closeSearch);
    return () => {
      document.removeEventListener('mousedown', closeSearch);
    }
  }, []);
  
  return (
    <StyledSearch ref={searchRef}>
      <div className="search">
        <div className="header" onClick={() => map && setIsActive(prev => !prev)}>
          <div className="icon">
            <img src={MarkerIcon} alt="marker" height="28" width="28"/>
          </div>
          <div className={`text ${state.id !== 0 ? 'selected' : ''}`}>{state.name}</div>
          <div className={`arrow ${isActive ? 'active' : ''} ${state.id !== 0 ? 'selected' : ''}`}>
            <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.4395 0.360473L16.0194 0.940347C16.5 1.42098 16.5 2.19975 16.0194 2.68038L9.06006 9.63968C8.57984 10.1199 7.80066 10.1203 7.32003 9.63968L0.360319 2.68038C-0.120311 2.19975 -0.119901 1.42057 0.360319 0.940347L0.940193 0.360473C1.42082 -0.120158 2.19959 -0.120158 2.68022 0.360473L8.18984 5.87009L13.6995 0.360473C14.1801 -0.120158 14.9589 -0.120158 15.4395 0.360473Z" fill="#72778F"/>
            </svg>
          </div>
        </div>
        <div className={`list ${isActive ? 'active' : ''}`}>
          <OverlayScrollbarsComponent
            style={{ maxHeight: '400px' }}
            options={{ scrollbars: { autoHide: 'scroll' } }}
          >
            <div 
              className={`list-item ${state.id === 0 ? 'active' : ''}`}
              onClick={() => onListItemClick(defaultState)}
            >
              <span>{defaultState.name}</span>
              <img className="check" src={CheckIcon} alt="check-icon"/>
            </div>
            {Object.keys(grouppedData).sort().map(key => (
              <div key={key}>
                <div className="list-group">{key}</div>
                {grouppedData[key].children.map(({ id, name }) => (
                  <div
                    key={id}
                    className={`list-item ${state.id === id ? 'active' : ''}`}
                    onClick={() => onListItemClick({ id, name })}
                  >
                    <span>{name}</span>
                    <img className="check" src={CheckIcon} alt="check-icon"/>
                  </div>
                ))}
              </div>
            ))}
          </OverlayScrollbarsComponent>
        </div>
      </div>
    </StyledSearch>
  );
}

const StyledSearch = styled.div`
  padding: 0 20px;
  position: absolute;
  left: 50%;
  top: 60px;
  transform: translateX(-50%);
  display: flex;

  @media screen and (max-width: 720px) {
    width: 100%;
    top: 20px;
  }

  .search {
    position: relative;
    background: #FFFFFF;
    box-shadow: 4px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    user-select: none;
  }

  .header {
    background: #FFFFFF;
    box-shadow: 4px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }

    .text {
      flex-grow: 1;
      font-weight: 600;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: 0.03em;
      color: #72778F;
      padding: 3px 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &.selected {
        color: #5D74E5;
      }

    }

    .arrow {
      padding-left: 15px;
      position: relative;
      flex-shrink: 0;

      svg {
        will-change: transform;
        transition: transform 0.2s linear;
      }

      path {
        transition: fill 0.2s linear;
      }

      &.active svg {
        transform: rotate(180deg);
      }

      &.selected path {
        fill: #5D74E5;
      }

      &::after {
        content: '';
        position: absolute;
        display: block;
        height: 100%;
        width: 2px;
        background: #EFF1FC;
        border-radius: 10px;
        top: 0;
        left: 0;
      }
    }
  }

  .list {
    overflow: hidden;
    padding: 0;
    height: 0;
    margin-top: 50px;

    &.active {
      padding: 10px 0;
      height: auto;
    }

    .list-group, .list-item {
      font-weight: 400;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: 0.03em;
      color: #72778F;
      padding: 10px 25px;
      white-space: nowrap;

      @media screen and (max-width: 720px) {
        white-space: normal;
      }

    }

    .list-group {
      font-weight: 600;
    }

    .list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding-right: 45px;

      &:hover, &.active {
        background: #EFF1FC;
        color: #5D74E5;
      }

      &.active .check {
        display: block;
      }
    }

    .check {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      display: none;
    }

  }

`;

export default Search;
