import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import mapboxGl from 'mapbox-gl';
import systemAffiliation from './data/system_affiliation.json';
import Contacts from './Contacts';
import Search from './Search';

const App = () => {
  const [map, setMap] = useState(null);
  const mapRef = useRef(null);

  useEffect(() => {
    mapboxGl.accessToken = 'pk.eyJ1IjoidG9wb2wiLCJhIjoiY2lndjM4eDNxMDA0M3Zma3JiOGRmcGNyOSJ9.tPBrXFyMAspRCTjyVKmx8A';

    const map = new mapboxGl.Map({
      container: mapRef.current || '',
      style: `mapbox://styles/mapbox/light-v10`,
      center: [-118.35908778239798, 34.104859813378454],
      zoom: 12,
      interactive: true,
      maxTileCacheSize: 100000,
      refreshExpiredTiles: true,
    });

    window.map = map;

    map.on("style.load", async () => {  

      const polygonColors = [
        0,
        'rgb(13,8,135)',
        100,
        'rgb(65,4,157)',
        200,
        'rgb(106,0,168)',
        300,
        'rgb(143,13,164)',
        400,
        'rgb(177,42,144)',
        500,
        'rgb(203,70,121)',
        1000,
        'rgb(225,100,98)',
        5000,
        'rgb(241,131,76)',
        10000,
        'rgb(252,166,54)',
        50000,
        'rgb(252,206,37)',
        100000,
        'rgb(252,206,37)',
      ];

      const styles = {
        type: 'fill',
        paint: {
          'fill-outline-color': 'rgba(0, 0, 0, 0.3)',
          'fill-opacity': 0.5,
          'fill-color': ['interpolate', ['linear'], ['to-number', ['get', 'population_density']], ...polygonColors]
        },
      }

      map.addSource('data-source-state',{
        type: 'vector',
        minzoom: 2,
        maxzoom: 4,
        tiles: [
          `${process.env.REACT_APP_BACKEND_URL}/tile/state/{z}/{x}/{y}`,
        ],
      });

      map.addSource('data-source-county',{
        type: 'vector',
        minzoom: 4,
        maxzoom: 7,
        tiles: [
          `${process.env.REACT_APP_BACKEND_URL}/tile/county/{z}/{x}/{y}`,
        ],
      });

      map.addSource('data-source-tract',{
        type: 'vector',
        minzoom: 7,
        maxzoom: 12,
        tiles: [
          `${process.env.REACT_APP_BACKEND_URL}/tile/tract/{z}/{x}/{y}`,
        ],
      });

      map.addSource('data-source-block_group',{
        type: 'vector',
        minzoom: 12,
        tiles: [
          `${process.env.REACT_APP_BACKEND_URL}/tile/block_group/{z}/{x}/{y}`,
        ],
      });

      map.addSource('hospital-source',{
        type: 'vector',
        minzoom: 2,
        tiles: [
          `${process.env.REACT_APP_BACKEND_URL}/hospital/{z}/{x}/{y}`,
        ],
      });

      map.addLayer({
        id: 'data-layer-state',
        source: 'data-source-state',
        'source-layer': 'state',
        minzoom: 2,
        maxzoom: 4,
        ...styles,
      });

      map.addLayer({
        id: 'data-layer-county',
        source: 'data-source-county',
        'source-layer': 'county',
        minzoom: 4,
        maxzoom: 7,
        ...styles,
      });

      map.addLayer({
        id: 'data-layer-tract',
        source: 'data-source-tract',
        'source-layer': 'tract',
        minzoom: 7,
        maxzoom: 12,
        ...styles,
      });

      map.addLayer({
        id: 'data-layer-block_group',
        source: 'data-source-block_group',
        'source-layer': 'block_group',
        minzoom: 12,
        ...styles,
      });

      const colors = systemAffiliation.map(({ id, color }) => ([id, color])).flat();

      map.addLayer({
        id: 'hospital-layer',
        minzoom: 2,
        type: 'circle',
        source: 'hospital-source',
        'source-layer': 'hospital_data',
        paint: {
          'circle-radius': 5,
          'circle-stroke-opacity': 0.8,
          'circle-stroke-color': '#FFF',
          'circle-stroke-width': 2,
          'circle-color': [
            'match',
            ['get', 'system_affiliation_id'], ...colors,
            // If no type mathed then it will be:
            '#777',
          ],
        },
      });

    });

    map.once('load', () => {
      setMap(map);
    });
  }, []);

  const onChange = (id) => {
    map.setFilter('hospital-layer', id === 0 ? null : ['==', ['get', 'system_affiliation_id'], id]);
    const current = systemAffiliation.find(i => i.id === id);
    if (current) {
      map.fitBounds(current.extent_arr, { padding: 100 })
    }
  }

  return (
    <StyledMap>
      <div id="map" ref={mapRef} />
      <Contacts />
      <Search map={map} onChange={onChange} />
    </StyledMap>
  );
}

const StyledMap = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  border-radius: inherit;
  display: flex;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;

  #map {
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }

  .mapboxgl-ctrl-bottom-right,
  .mapboxgl-ctrl-bottom-left {
    /* display: none; */
  }
`;

export default App;
